import styled, { css } from 'styled-components';

interface ISpinnerContainerProps {
  mt?: number;
}

export const Message = styled.span`
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #141414;
`;

export const LoadingContainer = styled.div<ISpinnerContainerProps>(
  (props) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${`${props.mt ?? 0}px`};
    flex-direction: column;
    row-gap: 8px;
  `
);
