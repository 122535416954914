import { Loading } from 'components/atoms/Loading';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const ChatPage = lazy(() => import('./components/pages/ChatPage'));
const CloneConversationPage = lazy(() => import('./components/pages/CloneConversationPage'));

const BASENAME = 'bees-ai-chat';
const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`${BASENAME}/share/:shareId`}
          element={
            <Suspense fallback={<Loading message="Loading data, wait a bit." mt={160} />}>
              <CloneConversationPage />
            </Suspense>
          }
        />
        <Route
          path={`${BASENAME}/`}
          element={
            <Suspense fallback={<Loading message="Loading data, wait a bit." mt={160} />}>
              <ChatPage />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
