import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFolder, IPrompt } from 'types/openai';
import { IPromptStore } from 'types/stores';

const initialState: IPromptStore = {
  predefinedPromptFolders: [],
  promptFolders: [],
  prompts: [],
  selectedPrompt: undefined,
};

const promptSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    setPrompts(state: IPromptStore, action: PayloadAction<IPrompt[]>) {
      state.prompts = action.payload;
    },
    setPredefinedPromptFolders(state: IPromptStore, action: PayloadAction<IFolder[]>) {
      state.predefinedPromptFolders = action.payload;
    },
    setPromptFolders(state: IPromptStore, action: PayloadAction<IFolder[]>) {
      state.promptFolders = action.payload;
    },
    setSelectedPrompt(state: IPromptStore, action: PayloadAction<IPrompt>) {
      state.selectedPrompt = action.payload;
    },
  },
});

export const { setPrompts, setPredefinedPromptFolders, setPromptFolders, setSelectedPrompt } =
  promptSlice.actions;

export default promptSlice.reducer;
