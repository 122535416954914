import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeletedFile } from 'api/services/files';
import { IFile } from 'types/openai';
import { IUploadStore } from 'types/stores';

const initialState: IUploadStore = {
  files: [],
  lastUploadOption: '',
  uploadedFiles: [],
  fakeDeletedFiles: [],
};

const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setFiles(state: IUploadStore, action: PayloadAction<IFile[]>) {
      state.files = action.payload;
    },
    setUploadedFiles(state: IUploadStore, action: PayloadAction<DeletedFile[]>) {
      state.uploadedFiles = action.payload;
    },
    setFakeDeletedFiles(state: IUploadStore, action: PayloadAction<IFile[]>) {
      state.fakeDeletedFiles = action.payload;
    },
    setLastUploadOption(state: IUploadStore, action: PayloadAction<string>) {
      state.lastUploadOption = action.payload;
    },
    clearAll(state: IUploadStore) {
      state.files = [];
      state.uploadedFiles = [];
      state.fakeDeletedFiles = [];
      state.lastUploadOption = '';
    },
  },
});

export const { setFiles, setLastUploadOption, setUploadedFiles, setFakeDeletedFiles, clearAll } =
  uploadSlice.actions;

export default uploadSlice.reducer;
