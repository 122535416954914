import { OptimizelyProvider as Provider, createInstance } from '@optimizely/react-sdk';
import { useAuthenticationService as authService } from 'admin-portal-shared-services';
import { Loading } from 'components/atoms/Loading';
import { FC, useEffect, useState } from 'react';
import { EnvConfig } from './EnvProvider';

interface OptimizelyProviderProps {
  env: EnvConfig;
  children: React.ReactNode;
}

interface OptimizelyProps {
  instance: any;
  isReady: boolean;
}

export const OptimizelyProvider: FC<OptimizelyProviderProps> = ({ env, children }) => {
  const authentication = authService();
  const [optimizely, setOptimizely] = useState<OptimizelyProps>({
    instance: null,
    isReady: false,
  });

  useEffect(() => {
    const initializeOptimizely = async () => {
      const instance = createInstance({
        sdkKey: env.optimizelyKey,
      });

      await instance.onReady();

      setOptimizely({
        instance,
        isReady: true,
      });
    };

    initializeOptimizely();
  }, [env.optimizelyKey]);

  if (!optimizely.isReady) {
    return <Loading message="Loading data, wait a bit." mt={160} />;
  }

  return (
    <Provider
      optimizely={optimizely.instance}
      user={{
        id: authentication.getUserId(),
        attributes: {
          id: authentication.getUserId(),
          email: authentication.getUserEmailB2C(),
          country: authentication.getUserCountry(),
        },
      }}
    >
      {children}
    </Provider>
  );
};
