import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChatInstruction, IInstructionFolder } from 'types/openai';
import { IInstructionStore } from 'types/stores';

const initialState: IInstructionStore = {
  chatInstructions: [],
  instructionsFolders: [],
  predefinedInstructionsFolders: [],
  selectedInstruction: undefined,
};

const instructionSlice = createSlice({
  name: 'instruction',
  initialState,
  reducers: {
    setChatInstruction(state: IInstructionStore, action: PayloadAction<IChatInstruction[]>) {
      state.chatInstructions = action.payload;
    },
    setInstructionFolders(state: IInstructionStore, action: PayloadAction<IInstructionFolder[]>) {
      state.instructionsFolders = action.payload;
    },
    setPredefinedInstructionFolders(
      state: IInstructionStore,
      action: PayloadAction<IInstructionFolder[]>
    ) {
      state.predefinedInstructionsFolders = action.payload;
    },
    setSelectedInstruction(state: IInstructionStore, action: PayloadAction<IChatInstruction>) {
      state.selectedInstruction = action.payload;
    },
  },
});

export const {
  setChatInstruction,
  setInstructionFolders,
  setPredefinedInstructionFolders,
  setSelectedInstruction,
} = instructionSlice.actions;

export default instructionSlice.reducer;
