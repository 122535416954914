import { useAnalyticsService } from 'admin-portal-shared-services';
import GlobalStyle from 'globalStyle';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from './Router';
import { IntlProvider } from './i18n';
import { EnvConfig, EnvProvider, OptimizelyProvider, StoreProvider } from './providers';

export default function App(props: EnvConfig) {
  const analyticsService = useAnalyticsService();

  const { segmentKey } = props;

  useEffect(() => {
    if (segmentKey) {
      analyticsService?.load(segmentKey);
    }
  }, [analyticsService, segmentKey]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60,
        cacheTime: 0,
        retry: false,
        refetchOnWindowFocus: false,
        onError: (error) => console.error(error),
      },
    },
  });

  return (
    <>
      <GlobalStyle />
      <OptimizelyProvider env={props}>
        <QueryClientProvider client={queryClient}>
          <EnvProvider env={props}>
            <StoreProvider>
              <IntlProvider>
                <Router />
              </IntlProvider>
            </StoreProvider>
          </EnvProvider>
        </QueryClientProvider>
      </OptimizelyProvider>
    </>
  );
}
