import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUpdateConversationParam } from 'api/services/conversations';
import { IConversation, IConversationListItem, IFolder } from 'types/openai';
import { IConversationStore } from 'types/stores';

const initialState: IConversationStore = {
  conversationFolders: [],
  conversations: [],
  conversationToUpdate: undefined,
  selectedConversation: undefined,
};

const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setConversationToUpdate(
      state: IConversationStore,
      action: PayloadAction<IUpdateConversationParam>
    ) {
      state.conversationToUpdate = action.payload;
    },
    setConversationFolders(state: IConversationStore, action: PayloadAction<IFolder[]>) {
      state.conversationFolders = action.payload;
    },
    setConversations(state: IConversationStore, action: PayloadAction<IConversationListItem[]>) {
      state.conversations = action.payload;
    },
    setSelectedConversation(state: IConversationStore, action: PayloadAction<IConversation>) {
      state.selectedConversation = action.payload;
    },
  },
});

export const {
  setConversationFolders,
  setConversationToUpdate,
  setConversations,
  setSelectedConversation,
} = conversationSlice.actions;

export default conversationSlice.reducer;
