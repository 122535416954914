import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMessage } from 'types/openai';
import { ISocketStore } from 'types/stores';

const initialState: ISocketStore = {
  isStopped: false,
  isStreaming: false,
  regenerateMessage: false,
  stopMessage: false,
  errorCode: undefined,
  messageToSend: undefined,
};

const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setIsStreaming(state: ISocketStore, action: PayloadAction<boolean>) {
      state.isStreaming = action.payload;
    },
    setErrorCode(state: ISocketStore, action: PayloadAction<number>) {
      state.errorCode = action.payload;
    },
    setIsStopped(state: ISocketStore, action: PayloadAction<boolean>) {
      state.isStopped = action.payload;
    },
    setRegenerateMessage(state: ISocketStore, action: PayloadAction<boolean>) {
      state.regenerateMessage = action.payload;
    },
    setStopMessage(state: ISocketStore, action: PayloadAction<boolean>) {
      state.stopMessage = action.payload;
    },
    setMessageToSend(state: ISocketStore, action: PayloadAction<IMessage>) {
      state.messageToSend = action.payload;
    },
  },
});

export const {
  setIsStreaming,
  setErrorCode,
  setIsStopped,
  setStopMessage,
  setMessageToSend,
  setRegenerateMessage,
} = socketSlice.actions;

export default socketSlice.reducer;
