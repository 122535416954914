import { LoadingDots } from '@hexa-ui/components';
import { LoadingContainer, Message } from './styles';

interface ILoadingProps {
  message?: string;
  mt?: number;
  size?: 'xlarge' | 'large' | 'medium';
}
export const Loading = ({ message, mt, size = 'large' }: ILoadingProps) => {
  return (
    <LoadingContainer mt={mt}>
      <LoadingDots dataTestId="loading-dots" size={size} />
      {message && <Message>{message}</Message>}
    </LoadingContainer>
  );
};
