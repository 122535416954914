import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInputStore } from 'types/stores';

const initialState: IInputStore = {
  content: null,
  tokenCount: 0,
};

const inputSlice = createSlice({
  name: 'input',
  initialState,
  reducers: {
    setContent(state: IInputStore, action: PayloadAction<string | null>) {
      state.content = action.payload;
    },
    setTokenCount(state: IInputStore, action: PayloadAction<number>) {
      state.tokenCount = action.payload;
    },
  },
});

export const { setContent, setTokenCount } = inputSlice.actions;

export default inputSlice.reducer;
